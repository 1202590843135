requirejs(['jquery'], function ($) {
	let widgets = gulpConfig.customs.widget_includer.widgets;
	let widget_positions = gulpConfig.customs.widget_includer.widget_positions;
  
	widgets.forEach(function(widget) {
		let widget_include_method = widget.include_method || "after";
		let widget_url = widget.url || false;
		
		if(widget_url && (widget.include_paths == undefined || widget.include_paths.includes(window.location.pathname))){
			let widget_position = widget.widget_position;

			// if config entry includes sidebar or main, predefined positions will be used, otherwise, use the given selector
			if(['main', 'sidebar'].includes(widget_position)){
				widget_position = widget_positions[widget.widget_position];
			} else if(widget.widget_position != null) {
				widget_position = widget.widget_position;
			} else {
				widget_position = widget_positions["main"];
			}

			let $include_selector = $(widget_position);		
			let position_class = "widget-includer--" + (widget.widget_position || main);			
  		let $widget_include_wrapper = $('<div class="widget-includer ' + position_class + '">');

			$widget_include_wrapper.css({
				"background": widget.background || "none",
				"margin": widget.margin || "0 auto 0",
				"padding": widget.padding || "0"
			})

			$.ajax({
				url: widget_url,
				success: function ($include_content) {
					$widget_include_wrapper.append($include_content);
					
					switch (widget_include_method) {
						case "before": $include_selector.before($widget_include_wrapper); break;
						case "after": $include_selector.after($widget_include_wrapper); break;				
						case "prepend": $include_selector.prepend($widget_include_wrapper); break;
						case "append": $include_selector.append($widget_include_wrapper); break;
					}				
				},
				error: function(){
						console.log( "Beim laden der Seite \"" + widget_url +"\" ist ein Fehler aufgetreten" );
				},
				cache: false
			})
		}
	})
})